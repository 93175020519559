import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { net.freshplatform.alrayada_web.pages.HomePage() }
        ctx.router.register("/ar/") { net.freshplatform.alrayada_web.pages.ar.PrivacyPolicyPage_ar()
                }
        ctx.router.register("/ar/delete-account-instructions/") {
                net.freshplatform.alrayada_web.pages.ar.deleteAccountInstructions.PrivacyPolicyPage_ar()
                }
        ctx.router.register("/ar/download-mobile-app/") {
                net.freshplatform.alrayada_web.pages.ar.downloadMobileApp.PrivacyPolicyPage_ar() }
        ctx.router.register("/ar/privacy-policy/") {
                net.freshplatform.alrayada_web.pages.ar.privacyPolicy.PrivacyPolicyPage_ar() }
        ctx.router.register("/delete-account-instructions/") {
                net.freshplatform.alrayada_web.pages.deleteAccountInstructions.DeleteAccountInstructions()
                }
        ctx.router.register("/download-mobile-app/") {
                net.freshplatform.alrayada_web.pages.downloadMobileApp.DownloadMobileAppPage() }
        ctx.router.register("/privacy-policy/") {
                net.freshplatform.alrayada_web.pages.privacyPolicy.PrivacyPolicyPage() }

    }
    router.setLegacyRouteRedirectStrategy(Router.LegacyRouteRedirectStrategy.DISALLOW)
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerComponentStyle(net.freshplatform.alrayada_web.components.BackToTopButtonStyle)
        ctx.theme.registerComponentStyle(net.freshplatform.alrayada_web.components.CheckIndicatorStyle)
        ctx.theme.registerComponentStyle(net.freshplatform.alrayada_web.components.NavigationIconStyle)
        ctx.theme.registerComponentStyle(net.freshplatform.alrayada_web.sections.about.AboutImageStyle)
        ctx.theme.registerComponentStyle(net.freshplatform.alrayada_web.sections.contact.components.MyTextInputStyle)
        ctx.theme.registerComponentStyle(net.freshplatform.alrayada_web.sections.main.helloTextStyle)
        ctx.theme.registerComponentStyle(net.freshplatform.alrayada_web.sections.main.MainImageStyle)
        ctx.theme.registerComponentStyle(net.freshplatform.alrayada_web.sections.main.compoments.NavigationItemStyle)
        ctx.theme.registerComponentStyle(net.freshplatform.alrayada_web.sections.main.compoments.BrandingLogoStyle)
        ctx.theme.registerComponentStyle(net.freshplatform.alrayada_web.sections.main.compoments.SocialLinkItemStyle)
        ctx.theme.registerComponentStyle(net.freshplatform.alrayada_web.sections.mobile_app.componenets.DownloadMobileAppButtonStyle)
        ctx.theme.registerComponentStyle(net.freshplatform.alrayada_web.sections.objective.components.ObjectiveCardStyle)
        ctx.theme.registerComponentStyle(net.freshplatform.alrayada_web.sections.portfolio.PortfolioSectionStyle)
        ctx.theme.registerComponentStyle(net.freshplatform.alrayada_web.sections.portfolio.PortfolioArrowIconStyle)
        ctx.theme.registerComponentStyle(net.freshplatform.alrayada_web.sections.products.components.ProductImageStyle)
        ctx.theme.registerComponentStyle(net.freshplatform.alrayada_web.sections.products_old.components.OldProductImageStyle)
        ctx.theme.registerComponentStyle(net.freshplatform.alrayada_web.sections.service.ServiceCardStyle)
        ctx.theme.registerComponentStyle(net.freshplatform.alrayada_web.styles.MainButtonStyle)
        ctx.theme.registerComponentStyle(net.freshplatform.alrayada_web.styles.MySelectStyle)
        ctx.theme.registerComponentStyle(net.freshplatform.alrayada_web.styles.DescTextStyle1)
        ctx.theme.registerComponentStyle(net.freshplatform.alrayada_web.styles.ReadableTextStyleOnWhiteBackground)
        net.freshplatform.alrayada_web.initSilk(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    AppGlobals.initialize(mapOf("title" to "Alrayada"))
    renderComposable(rootElementId = "root") {
        net.freshplatform.alrayada_web.MyApp {
            router.renderActivePage { renderWithDeferred { it() } }
        }
    }
}
